import "./More.css";

export default function More({ timeZone, dayOfYear, dayOfWeek, weekNum }) {
   // console.log(timeZone);

   return (
      <div className="more-info-container">
         <div className="more-info--left">
            <div className="more-info">
               <p>Current timezone</p>
               <h4>{timeZone}</h4>
            </div>
            <div className="more-info">
               <p>day of the year</p>
               <h4>{dayOfYear}</h4>
            </div>
         </div>
         <div className="more-info--right">
            <div className="more-info">
               <p>day of the week</p>
               <h4>{dayOfWeek}</h4>
            </div>
            <div className="more-info">
               <p>week number</p>
               <h4>{weekNum}</h4>
            </div>
         </div>
      </div>
   );
}
