import { useEffect, useState } from "react";
import { useFetch } from "../hooks/useFetch";
import "./Quote.css";

export default function Quote() {
   const { data, isPending } = useFetch(
      "https://api.quotable.io/quotes/random"
   );

   const [quote, setQuote] = useState("");
   const [author, setAuthor] = useState("");

   useEffect(() => {
      if (data) {
         // console.log(data[0]);
         setQuote(data[0].content);
         setAuthor(data[0].author);
      }
   }, [data]);

   const handleClick = () => {
      // console.log("!TODO: HANDLE CLICK EVENT FOR NEW QUOTE");
      fetch("https://api.quotable.io/quotes/random")
         .then((res) => res.json())
         .then((json) => {
            setQuote(json[0].content);
            setAuthor(json[0].author);
         });
   };

   return (
      <div className="quote-container">
         <div className="quote">
            <p>{quote}</p>
            <h5>{author}</h5>
         </div>
         <div
            className="get-new-quote"
            onClick={() => {
               handleClick();
            }}
         >
            <img src="/assets/desktop/icon-refresh.svg" alt="refresh quote" />
         </div>
      </div>
   );
}
