import { useState, useEffect } from "react";
import { useFetch } from "../hooks/useFetch";
import "./Time.css";

export default function Time({
   isPending,
   hour,
   minutes,
   timeOfDay,
   city,
   country,
   iconSrc,
}) {
   return (
      <div className="time-container">
         <div className="greeting">
            <img src={iconSrc} alt="" />
            <h3>{timeOfDay}</h3>
         </div>
         <div className="time">
            {isPending ? (
               "Loading..."
            ) : (
               <h1>
                  {hour}:{minutes}
                  {/* <span>{utc}</span> */}
               </h1>
            )}
         </div>
         <div className="location">
            {isPending ? (
               "Loading..."
            ) : (
               <h2>
                  In {city}, {country}
               </h2>
            )}
         </div>
      </div>
   );
}
