import { useState, useEffect } from "react";
import { useFetch } from "./hooks/useFetch";
import MoreLessBtn from "./componants/MoreLessBtn";
import Quote from "./componants/Quote";
import Time from "./componants/Time";
import "./App.css";
import More from "./componants/More";

function App() {
   // FETCH TIME AND USER IP
   const { data: date, isPending } = useFetch("http://worldtimeapi.org/api/ip");
   const [hour, setHour] = useState("");
   const [minutes, setMinutes] = useState("");

   const [utc, setUtc] = useState({});
   const [city, setCity] = useState("");
   const [country, setCountry] = useState("");

   const [iconSrc, setIconSrc] = useState("");
   const [timeOfDay, setTimeOfDay] = useState("");

   // MORE INFO
   const [timeZone, setTimeZone] = useState("");
   const [dayOfYear, setDayOfYear] = useState("");
   const [dayOfWeek, setDayOfWeek] = useState("");
   const [weekNum, setWeekNum] = useState("");

   useEffect(() => {
      const appBg = document.querySelector(".App");

      let myDate = "";

      if (date) {
         myDate = new Date(date.unixtime * 1000);
      }

      if (myDate) {
         setHour(myDate.getHours());
         setMinutes(myDate.getMinutes());

         // console.log(myDate.getHours());

         if (myDate.getHours() <= 18) {
            setIconSrc("/assets/desktop/icon-sun.svg");
            appBg.classList.add("light");
         } else {
            setIconSrc("/assets/desktop/icon-moon.svg");
            appBg.classList.add("dark");
         }

         if (myDate.getHours() > 3 && myDate.getHours() <= 11) {
            setTimeOfDay("Good morning");
         } else if (myDate.getHours() >= 12 && myDate.getHours() <= 17) {
            setTimeOfDay("Good afternoon");
         } else {
            setTimeOfDay("Good evening");
         }
         setTimeZone(date.timezone);
         setDayOfYear(date.day_of_year);
         setDayOfWeek(date.day_of_week);
         setWeekNum(date.week_number);

         // console.log(date);
      }
   }, [date]);

   useEffect(() => {
      if (date) {
         fetch(
            `https://api.ipbase.com/v2/info?apikey=ipb_live_Z5lxrhgX5YKUDb8VEWwp9YFOhIB61KzXNJczvliK&ip=${date.client_ip}`
         )
            .then((res) => res.json())
            .then((json) => {
               setCity(json.data.location.city.name);
               setCountry(json.data.location.country.alpha2);
               setUtc(json.data.timezone.code);
            });
      }
   }, [date]);

   const handleMore = () => {
      const container = document.querySelector(".container");
      const moreBtn = document.querySelector(".more-btn-container .more");

      container.classList.toggle("showMore");

      if (container.classList.contains("showMore")) {
         moreBtn.innerHTML = `Less`;
      } else {
         moreBtn.innerHTML = `More`;
      }
   };

   return (
      <div className="App">
         <div className="img-filter">
            <div className="container">
               <div className="content-container">
                  <div className="left-wrap">
                     <Quote />
                     <Time
                        isPending={isPending}
                        hour={hour}
                        minutes={minutes}
                        iconSrc={iconSrc}
                        timeOfDay={timeOfDay}
                        city={city}
                        country={country}
                        utc={utc}
                     />
                  </div>
                  <div className="right-wrap">
                     <MoreLessBtn handleMore={handleMore} />
                  </div>
               </div>
               <div className="more-container">
                  <More
                     timeZone={timeZone}
                     dayOfYear={dayOfYear}
                     dayOfWeek={dayOfWeek}
                     weekNum={weekNum}
                  />
               </div>
            </div>
         </div>
      </div>
   );
}

export default App;
