import "./MoreLessBtn.css";

export default function MoreLessBtn({ handleMore }) {
   return (
      <div className="more-btn-container">
         <button onClick={handleMore}>
            <span className="more">More</span>
            <span className="moreBtn">
               <img src="/assets/desktop/icon-arrow-up.svg" alt="" />
            </span>
         </button>
      </div>
   );
}
